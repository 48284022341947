<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
    <div class="q-layout q-layout--standard">
      <MenuOverlay :openMenu="openMenu" />
      <PageHeader :showMenu="true" />
      <slot />
      <GetInTouch v-if="showGetInTouch" />
      <PageFooter />

      <SlideDownTransition>
        <CookieBanner v-if="openCookie" />
      </SlideDownTransition>
      <Transition name="slide-fade-down">
        <ModalContainer v-if="openModal" :iframeLink="'https://taccd1583.emailsys1a.net/215/2854/ca6bcfee26/subscribe/form.html?_g=1738678290%22'"></ModalContainer>
      </Transition>
    </div>
  </Html>
</template>

<script setup>
import AOS from "aos";
import { useGeneralStore } from "@/stores/general";
import { CookieHandling } from "@/assets/js/cookieConsent";

const generalStore = useGeneralStore();

const head = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
});

const { $bus } = useNuxtApp();

const htmlAttrs = computed(() => head.value.htmlAttrs);

const route = useRoute();

let showGetInTouch = ref(true);
const openMenu = ref(false);
const openCookie = ref(false);
const openModal = ref(false);

const { locale } = useI18n();
const store = useGeneralStore();
const { darkMode, configEnded } = storeToRefs(store);

AOS.init();

// fill articleList
store.setArticleList(locale.value);

watch(locale, () => {
  store.setArticleList(locale.value);
});

watch(
  () => route.path,
  () => {
    console.log("Changed route");
  },
  { immediate: true, deep: true }
);

// check and set of darkmode
if (localStorage.getItem("wsDarkMode") === "true") {
  store.saveDarkMode(true);
} else if (localStorage.getItem("wsDarkMode") === "false") {
  store.saveDarkMode(false);
}

const setDarkMode = () => {
  if (darkMode.value) {
    document.body.classList.add("body--dark");
  } else {
    document.body.classList.remove("body--dark");
  }
};

setDarkMode();

watch(darkMode, () => {
  setDarkMode();
});

store.saveLanguage(locale.value);

// check flow speed
if (localStorage.getItem("flowSpeed")) {
  store.setFlowSpeed(Number(localStorage.getItem("flowSpeed")));
}

const cookie = new CookieHandling(store);

if (cookie.getCookie("showCookieConsent") === "true") {
  openCookie.value = true;
} else {
  openCookie.value = false;
}

if (cookie.getCookie("marketingCookies") === "true") {
  console.log("CHECKED MARKETING COOKIES", "CURRENT STATE IS TRUE");
  store.setMarketingCookies(true);
} else {
  store.setMarketingCookies(false);
}

let openMarketingModal = computed ( () => {
  return store.openMarketingModal
})

if(!localStorage.getItem("marketingModal")) {
  localStorage.setItem("marketingModal", store.openMarketingModal);
}

if(localStorage.getItem("marketingModal") === "false") {
  console.log("OLE")
  setTimeout(() => {
    generalStore.setMarketingModalState(true)
    openModal.value = true
    console.log("OLE 2")
  }, 3000)
} else if (localStorage.getItem("marketingModal") === "true") {
  openModal.value = false
}

$bus.$on("closeMarketingModal", (_state) => {
  openModal.value = _state.state
})

// New event bus system, should be used global in the future
// Currently used for extern trigger of Cookie Consent Banner
$bus.$on("openCookieConsent", (_state) => {
  openCookie.value = _state.state;
});

$bus.$on("toggleMenu", (_state) => {
  openMenu.value = _state;
});
</script>
